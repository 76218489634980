.eulaList{
    line-height: 2rem;
    padding-left: 0;
    list-style: none;
}

.companyInfo{
    margin-top: 10vh;
    list-style: none;
    padding-left: 0;
}

.eulaTextArea{
    margin-top: 30px
}