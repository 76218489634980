.inputUrl :-webkit-autofill,
inputUrlt:-webkit-autofill:hover,
inputUrl:-webkit-autofill:focus,
inputUrl:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.tabs :global(.ant-tabs-nav) {
	margin-bottom: 0;
	color: #8090B8;
}

.tabs :global(.ant-tabs-nav)::before {
	border-bottom: 1px solid #E9EEF7;
}

.tabs :global(.ant-tabs-tab) {
	padding: 12px 24px;
}

.documentationArea {
	width: 100%;
}

.title {
	margin: 0px
}

.documetationListWrapper {
	width: 100%;
	position: relative
}

.iconDownload{
	color: #8090B8
}

.divider{
	height: 52px
}

.table{
	overflow-x: auto;
}