.inputUrl :-webkit-autofill,
inputUrlt:-webkit-autofill:hover,
inputUrl:-webkit-autofill:focus,
inputUrl:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.tabs :global(.ant-tabs-nav) {
	margin-bottom: 0;
	color: #8090B8;
}

.tabs :global(.ant-tabs-nav)::before {
	border-bottom: 1px solid #E9EEF7;
}

.tabs :global(.ant-tabs-tab) {
	padding: 12px 24px;
}

.table :global(.ant-table-cell) {
	vertical-align: top;
	padding: 8px 0 8px 8px !important;
	font-size: 10px;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	cursor: pointer;
}

.table :global(.ant-table-cell):first-child {
	vertical-align: top;
	font-size: 10px;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	padding: 8px 0 8px 24px !important;
}

.table :global(.ant-table-cell)::before {
	width: 0 !important;
}

.table :global(.ant-table-thead) th {
	padding: 16px 0 0 8px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px !important;
}

.table :global(.ant-table-thead) th:first-child {
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	padding: 16px 0 0 24px !important;
	font-size: 12px !important
}

.table :global(.ant-table-column-sorters) {
	flex-direction: row-reverse !important;
}

.cellText {
	font-size: 10px;
}

.table :global(.ant-table-thead) tr {
	box-sizing: border-box;
	height: 50px;
}

.banner {
	background: transparent;
	height: 80px;
}

.bannerTitle {
	font-size: 24px;
	font-weight: 600;
}

/* .datepicker :global(.ant-picker-footer) {
	display: none!important;
	color: red;
} */
.gradient {
	width: 80%;
	height: 10px;
	background: linear-gradient(to right, yellow, #B00000);
	position: relative;
}

.triangle {
	width: 0;
	height: 0;
	opacity: .4;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 15px solid grey;
}

.blur {
	filter: blur(6px);
}

.icon {
	color: #8090B8
}

.apiDivider {
	height: 52px
}

.apiAndUrlCheckTable {
	overflow-x: auto
}

.wrapper {
	width: 100%
}

.urlCheckForm {
	width: 100%;
	flex-wrap: nowrap;
	position: relative;
}

.urlSearch {
	height: 44px;
}

.urlCheckSubmitButton {
	height: 44px;
	width: 100%;
	background: #2E496F;
}

.checkUrlCard {
	display: flex;
	justify-content: space-between;
	align-items: center
}

.checkUrlCardTitle {
	font-size: 20px;
}

.checkUrlDetailsButton {
	height: 44px;
	flex-basis: 200px;
	flex-shrink: 1;
	background: #2E496F;
}

.kasperskyAlert {
	display: flex;
	justify-content: center
}

.documentationWarpper {
	width: 100%;
	position: relative;
}

.modalConfirmWrapper {
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: column;
	gap: 24px;
	position: relative;
}

.modalConfirmButtonsArea {
	display: flex;
	gap: 24px,
}

.modalConfirmButton {
	flex-grow: 1;
}

.modalConfirmButtonSuccess {
	flex-grow: 1;
	background: #2E496F;
}

.urlAssetsConnectionTooltip {
	white-space: nowrap
}

.urlAssetsConnectionStatus {
	font-size: 10px
}

.urlAssetsFallbackKfeedModeBadge {
	border-radius: 4px;
	height: 26px;
	box-sizing: border-box;
	padding: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	text-align: center;
}

.urlAssetsKfeedDetailsColumn {
	display: flex;
	width: 90px;
	justify-content: space-between
}

.urlAssetsKfeedDetailsColumnText {
	color: blue;
	cursor: pointer
}

.urlAssetsKfeedDetailsColumnButton {
	background: #1677ff;
	border-radius: 4px;
	padding: 2px;
	width: 50px;
	display: flex;
	justify-content: center;
	color: white;
}

.urlAssetsKfeedModeColumnWrapper {
	gap: 4px
}

.urlAssetsKfeedModeColumnSelector {
	width: 150px;
	line-height: 20px;
	height: 28px;
}

.urlAssetsKfeedModeColumnSelectorBadge {
	font-size: 10px;
	border-radius: 4px;
	height: 26px;
	box-sizing: border-box;
	padding: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	text-align: center;
}


.urlAssetsKfeedModeColumnBadge {
	font-size: 10px;
	border-radius: 4px;
	height: 26px;
	box-sizing: border-box;
	padding: 0 20px 0 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.urlAssetsKfeedModeColumnEditArea {
	display: flex;
	gap: 5px
}

.urlAssetsKfeedModeTooltip {
	color: #4B4F55
}

.urlAssetsKfeedModeEditingIcon {
	font-size: 18px;
	align-self: end;
	color: #8090B8
}

.urlAssetsFrequencyFilterButton {
	background: transparent;
	border: none
}

.urlAssetsWrapper {
	width: 100% !important;
	position: relative
}

.urlAssetsFiltersArea {
	display: flex;
	gap: 15px;
	align-items: flex-start
}

.urlAssetsFiltersAreaPrefixIcon {
	color: #C1C2C9
}

.urlAssetsFiltersAreaSearchInput {
	color: black;
	width: 356px;
	height: 32px;
	padding: 8px 12px;
}

.urlAssetsFiltersAreaFrequencyFilter {
	align-self: flex-end;
}

.urlAssetsFiltersAreaFrequencyFilterIcon {
	padding: 5px 0px;
	color: #6679A9
}

.urlAssetsFiltersAreaFrequencyFilterValue {
	padding: 0 5px;
	font-size: 12px;
}

.urlAssetsFiltersAreaResetFiltersButton {
	margin-left: 5px;
}

.urlFilterAreaNewWrapper {
	width: 100% !important
}

.urlFilterAreaNewTitleWrapper {
	justify-content: space-between;
	display: flex;
	align-items: center;
}

.urlFilterAreaNewKasperskyTitle {
	display: flex;
	align-items: center;
}

.urlItemModalTitleWrapper {
	display: flex;
	justify-content: space-between;
	padding-inline: 24px;
	height: 64px;
	z-index: 10;
}

.urlItemModalTitle {
	font-size: 24px;
	font-weight: 600;
}

.urlItemModalCloseButton {
	width: 32px;
	height: 32px;
	background-color: #F6F8FD;
	padding: 0px;
	margin-right: -32px;
}

.urlItemModalCloseButtonIcon {
	width: 12px;
	height: 12px;
}

.urlItemModalUrl {
	margin: 0;
	padding-inline: 24px;
	font-size: 16px !important;
	font-weight: 600 !important;
	color: #327FEF !important;
}

.urlItemModalError {
	font-size: 20px
}

.urlItemModalBadgeArrayWrapper {
	display: flex;
	align-items: center;
	height: 24px
}

.urlItemModalBadgeCategories :global(.ant-scroll-number) {
	background-color: rgba(235, 87, 87, 0.1);
	color: #EB5757;
	border-radius: 8px;
	font-size: 14px;
	font-weight: 600;
}

.urlItemModalBadgePACategories :global(.ant-scroll-number) {
	background-color: rgba(33, 150, 83, 0.1);
	color: rgba(33, 150, 83, 1);
	border-radius: 8px;
	font-size: 14px;
	font-weight: 600;
}

.urlItemModalSecondaryText {
	color: #C1C2C9
}

.urlItemModalOtherBadges :global(.ant-scroll-number) {
	border-radius: 6px;
	background-color: white;
	color: currentcolor;
	margin-right: 5px;
	border: 1px solid lightgrey;
}

.urlItemModalPopularityTitleWrapper {
	display: flex;
	gap: 5px !important;
}

.urlItemModalPopularityTitleTooltip {
	color: #4B4F55 !important
}

.urlItemModalPopularityInfoIcon {
	font-size: 14px;
	color: #7A7D86;
	cursor: pointer
}

.urlItemModalOtherBadgesGap {
	font-size: 14px;
}

.urlItemModalHashesText {
	font-size: 10px;
}

.urlItemModalWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 24px;
	margin-bottom: 24px;
	padding-left: 24px;
}

.urlKfeedUrlWrapper {
	word-wrap: break-word;
	width: 250px
}


.urlKfeedSecretUrlWrapper {
	display: flex;
	gap: 5px;
	overflow-wrap: break-word;
	word-break: break-word;
	flex-wrap: nowrap;
	width: 250px
}

.urlKfeedSecretUrl {
	width: 235px
}

.urlKfeedSecretUrlTooltipText {
	color: #4B4F55 !important;
	font-size: 14px !important;
}

.urlKfeedInfoIcon {
	font-size: 10px;
	color: #7A7D86;
	cursor: pointer
}

.urlKfeedDetailsButton {
	font-size: 20px;
	color: rgb(128, 144, 184)
}

.urlKfeedFiltersAreaSearchInput {
	color: black;
	width: 356px;
	height: 32px;
}

.urlKfeedResetSearchIcons {
	color: red
}

.urlKfeedFrequencyButton {
	display: flex;
	align-items: center
}

.urlKfeedExpandIcon {
	font-size: 12px;
	padding-top: 4px
}

.urlKfeedGraphCollapse {
	background-color: white;
	align-content: center;
	border-radius: 8px;
	font-size: 18px;
	font-weight: 600;
	min-height: 60px;
	padding: 0px
}

.urlKfeedGraphCollapseTitleWrapper {
	display: flex;
	justify-content: space-between;
	padding: 0px;
	align-self: center
}

.urlKfeedCollapseTitleDateRange {
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	border: 1px solid #E9EEF7;
	border-radius: 8px;
	height: 44px;
	width: 230px
}

.urlKfeedCollapseTitleDateRangeIcon {
	font-size: 16px;
	color: #6679A9
}

.urlKfeedCollapseTitleDateRangeText {
	font-size: 14px;
	color: #4B4F55;
	font-weight: 400
}

.urlKfeedtableWrapper {
	background: white;
	border-radius: 8px;
	padding: 0px;
}
.tableContainer {
	position: relative;
}

.kfeedTableLoader {
	position: absolute !important;
	top: 400% !important;
	transform: translate(-50%, -50%);
}

.tableLoader {
	position: fixed !important;
	top: 50% !important;
	transform: translate(-50%, -50%);
}

.textOverflow {
	overflow-wrap: break-word;
	word-break: break-word;
	white-space: normal
}

.nameOverflow {
	overflow-wrap: break-word;
	word-break: normal;
	white-space: normal
}