.layout {
    min-height: 100vh;
}

.layoutContent {
    padding: 28px;
}

.dot {
    display: inline-block;
    border-radius: 50%
}