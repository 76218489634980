.form :global(.ant-form-item-label) {
	padding: 0;
}

.form :global(.ant-form-item-label) label {
	color: #4B4F55;
	height: fit-content !important;
}

.form :global(.ant-form-item-control) input {
	border-radius: 4px !important;
	height: 48px;
	padding: 12px 16px;
}

.form :global(.ant-input-password) input {
	height: auto;
	padding: 0;
}

.form :global(.ant-input-password) {
	border-radius: 4px !important;
	height: 48px;
	padding: 12px 16px;
}

.form :global(.ant-form-item-control-input) {
	min-height: 20px !important;
}

.passwordRecoverWrapper {
	display: grid;
	grid-template-columns: auto 490px;
	height: 100vh;
}

.backgroundImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.passwordRecoverFormWrapper {
	padding: 80px 64px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.passwordRecoverFormTitle {
	margin-bottom: 24px !important;
}

.passwordRecoverGoToLoginWrapper {
	display: flex
}

.passwordRecoverGoToLoginDivider {
	flex-grow: 1;
}

.passwordRecoverGoToLogin {
	padding: 0px;
	font-size: 14px
}

.passwordRecoverButtonWrapper {
	margin-bottom: 0px
}

.passwordRecoverButton {
	width: 100%;
	border-radius: 4px;
	font-size: 14px;
	background: #2E496F;
}