.pageWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background: white;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center
}