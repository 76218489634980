.descriptions :global(.ant-descriptions-view) {
	border: none !important;
}

.descriptions :global(.ant-descriptions-item-label) {
	border: none !important;
}

.descriptions :global(.ant-descriptions-row):nth-child(2n) {
	background-color: #FCFDFF !important;
}

.noMargin {
	margin: 0px !important
}

.threatVaultAreaWrapper {
	width: 100%
}

.threatVaultSearchForm {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 200px;
	gap: 20px;
	position: relative;
}

.threatVaultSearchFormSelector {
	height: 44px
}

.threatVaultSearchFormButtonWrapper {
	margin-top: 21.89px
}

.threatVaultSearchFormButton {
	width: 100%;
	background: #2E496F
}

.threatVaultNotFoundMessage {
	color: #2E496F;
	align-items: center;
	display: flex;
	margin-top: 24px;
}


.threatVaultCardCveTable {
	overflow-x: auto;
}

.threatVaultCardShaTable {
	overflow-x: auto;
	margin-top: 30px
}

.threatVaultModalTitle {
	display: flex;
	justify-content: flex-end;
	padding-inline: 16px;
	height: 56px;
}

.closeModalButton {
	width: 32px;
	height: 32px;
	background-color: #F6F8FD;
	padding: 0px;
}

.closeModalIcon {
	width: 12px !important;
	height: 12px !important;
}

.threatVaultModalWrapper {
	display: flex;
	padding-inline: 48px !important;
	padding-bottom: 32px !important;
	
}

.threatVaultModalTitle:global(.ant-typography) {
	justify-content: start;
  }