.releaseNotesWrapper {
    display: flex;
    flex-direction: column
}

.releaseNotes {
    padding: 12px 16px;
    border-bottom: 1px solid #E9EEF7;
    width: 100%;
    font-size: 14px;
    color: #4B4F55;
}

.detailsTitleArea {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

.detailsTitle {
    margin: 0px 0px 5px 0px
}

.releaseDateArea {
    display: flex;
    align-items: baseline;
}

.releaseDateIcon {
    font-size: 24px;
}

.detailsLinksArea {
    gap: 32px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: flex-end;
}

.detailsTitleAreaWrapper {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding-top: 15px;
}

.marginTop{
    margin-top: 15px;
}

.wholeWidth{
    width: 100%;
    overflow-y: auto;
}