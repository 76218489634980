.chartjs-tooltip {
    display: flex;
    align-items: center;
}

.chartjs-tooltip .tooltip-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

.stacked-bar-chart {
    height: 330px;
    width: 100%
}

.legend-monitoring {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    height: 16px;
    margin-top: 16px
}

.legend-replacing {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    height: 16px;
    margin-top: 5px;
    margin-bottom: 4px
}

.legend-titles {
    font-size: 12px
}

.requests-mark {
    height: 16px;
    width: 16px;
    border-radius: 6px
}

.straight{
    background-color: #E52E5A
}

.secondary{
    background-color: #F6B9C8
}

.straight-replacing{
    background-color: #219653
}

.secondary-replacing{
    background-color: #ACDCC1
}