.card {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title{
    margin: 0px
}

.cardContent{
    display: flex; 
    width: 100%; 
    justify-content: center
}

.button{
    margin: 20px auto
}