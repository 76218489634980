.httpDevices {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 8px;
}

.httpDevices> :nth-child(2n - 1) {
	color: #7A7D86;
}

.limitsCards {
	display: grid;
	gap: 6px;
	grid-template-columns: repeat(6, 1fr);
}

.addButton {
	border: 1px solid var(--primary-2-normal, #327FEF);
	background-color: "transparent";
	height: 40px;
	border-radius: 4px;
	color: #327FEF;
	font-size: 14px;
	display: flex;
	align-items: center;
	width: 137px;
	box-sizing: border-box;
	margin: 0;
	padding: 8px 24px;
}

.limitsCards>div {
	padding: 20px 16px;
	background: #F6F8FD;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.limitsCards * {
	font-size: 16px;
}

.limitsCards>div> :first-child {
	color: #6679A9;
	text-transform: uppercase;
	font-size: 12px;
}

.toggleButton {
	display: inline-flex;
}

.toggleButton span {
	display: flex;
	padding-inline: 16px;
	align-items: center;
	border-radius: 4px;
	background: #FFF;
	border: 1px solid #E9EEF7;
	font-weight: 400;
	cursor: pointer;
	user-select: none;
	transition-property: color, border-color;
	transition-duration: 250ms;
}

.toggleButton span:hover {
	border-color: #a7c3ee;
}

.toggleButton input:checked+span {
	color: #327FEF;
	border-color: #327FEF;
}

.toggleButton input {
	position: absolute;
	opacity: 0;
}

.table :global(.ant-table-row) {
	cursor: pointer;

}

.table :global(.ant-table-cell) {
	vertical-align: top;
	padding: 8px 0 8px 24px !important;
}

.table :global(.ant-table-thead) th {
	padding: 16px 8px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px;
}

.table :global(.ant-table-thead)>tr>td {
	background-color: #FCFDFF !important;
	border: none;
}

.rowHighlight {
	background: #FFF9EC;
}

.descriptions {
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.descriptions>* {
	padding: 8px 16px;
}

.descriptions> :nth-child(4n),
.descriptions> :nth-child(4n - 1) {
	background: #FCFDFF;
}

.tableModal :global(.ant-table-thead) th {
	padding: 16px 24px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px;
}

.tabs :global(.ant-tabs-nav) {
	margin-bottom: 0;
	color: #8090B8;
}

.tabs :global(.ant-tabs-nav)::before {
	border-bottom: 1px solid #E9EEF7;
}

.tabs :global(.ant-tabs-tab) {
	padding: 12px 24px;
	margin: 0 !important;
}

.table :global(.ant-table-cell) {
	vertical-align: top;
	padding: 8px 0 8px 8px !important;
	font-size: 10px;
	font-style: normal;
	/* line-height: 16px !important; */
	/* color: #4B4F55 !important; */
}

.table :global(.ant-table-cell)::before {
	width: 0 !important;
}

.form :global(.ant-form-item-label) {
	padding: 0;
	color: red !important
}

.form :global(.ant-form-item-label) label {
	color: #4B4F55 !important;
	font-weight: 400;
	height: fit-content !important;
}

.form :global(.ant-form-item-control) input {
	border-radius: 4px !important;
	/* padding: 12px 16px; */

}

.form :global(.ant-input) input {
	padding: 0 !important;
}

.form :global(.ant-input-lg) input {
	padding: 0 !important;
}

.form :global(.ant-select-selector) {
	border-radius: 4px !important;
	/* height: 44px !important; */

}

.form :global(.ant-form-item-control-input) {
	min-height: 20px !important;
}

.input :-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.validators {
	height: 144px !important;
}

.formItem {
	display: flex !important;
	gap: 24px;
	justify-content: space-between !important;
	/* width: '100% !important'  */
}

.input_file {
	position: relative;
	display: flex;
	padding: 2px 12px 2px 2px;
	border-radius: 4px;
	border: 1px solid var(--additional-s-8, #CED9EE);
	background: #FFF;
	width: 292px;
	height: 44px;
	box-sizing: border-box;
	align-items: center;
}

.input_file_text {
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	/* height: 44px; */
	display: block;
	float: left;
	box-sizing: border-box;
	font-weight: 400;
	overflow: hidden;
	max-width: 150px;
	padding-left: 12px;
	color: var(--achrom-gray-4, #989AA5);
}

.input_file_text_in {
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	/* height: 44px; */
	display: block;
	float: left;
	box-sizing: border-box;
	font-weight: 400;
	overflow: hidden;
	max-width: 200px;
	padding-left: 12px;
	color: var(--achrom-gray-4, #989AA5);
}

.input_file_btn {
	position: relative;
	border-radius: 2px;
	background: var(--additional-s-9, #E9EEF7);
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
	vertical-align: middle;
	color: var(--primary-2-normal, #327FEF);
	text-align: center;
	/* height: 40px; */
	padding: 8px 12px;
	border: none;
	margin: 0;
	box-sizing: border-box;
	width: 129px !important;
}

.input_file input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}

/* Focus
.input_file input[type=file]:focus+.input_file_btn {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Hover/active */
/* .input_file:hover .input_file_btn {
	background-color: #59be6e;
} */

/* .input_file:active .input_file_btn {
	background-color: #2E703A;
} */

/* Disabled */
/* .input_file input[type=file]:disabled+.input_file_btn {
	background-color: #eee;
}  */

.placeholderWrapper {
	display: flex;
	flex-direction: column;
	gap: 2px;

}

.placeholderText {
	color: var(--achrom-gray-3, #7A7D86);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 138.462% */
}

.detailWrapper {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background: var(--additional-s-7, #F6F8FD);
	padding: 24px;
	gap: 8px;
}

.detailTitle {
	color: var(--achrom-gray-3, #7A7D86);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
}

.detailText {
	color: var(--achrom-gray-1, #333);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	/* 150% */
}

.instanceForm :global(.ant-table-thead) th {
	padding: 8px 8px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px;
}

.instanceForm :global(.ant-table-thead) td {
	padding: 8px 8px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px;
}

.instanceForm :global(.ant-table-cell) {

	font-size: 10px !important;
}

.tableLoader {
	position: fixed !important;
	top: 55% !important;
	transform: translate(50%, 50%)
}

.addEdlListNewModalCloseIconWrapper {
	display: flex;
	justify-content: flex-end;
	padding-inline: 16px;
	height: 56px;
}

.addEdlListNewModalCloseIcon {
	width: 12px;
	height: 12px
}

.addEdlListNewModalCloseButton {
	width: 32px !important;
	height: 32px;
	background-color: #F6F8FD;
	padding: 0
}

.addEdlListNewModalWrapper {
	padding: 0 48px 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.addEdlListNewModalTitle {
	margin-bottom: 24px !important;
}

.addEdlListNewModalFormWrapper {
	width: 100%;
	position: relative
}

.addEdlListNewModalFormSwitchWrapper {
	margin-bottom: 24px;
	height: 65px
}

.addEdlListNewModalFormSwitch {
	width: 44px
}

.addEdlListNewModalFormSwitchText {
	margin-left: 12px;
	color: #7A7D86;
	font-size: 16px;
	font-weight: 500;
}

.nameLinkEdlExternal {
	font-size: 12px
}

.demo {
	font-size: 8px;
	background: rgb(0, 0, 128);
	border-radius: 4px;
	padding: 2px;
	text-align: center;
	color: white
}

.connectSourceLink {
	background-color: transparent;
	height: 20px !important;
	width: 120px !important;
	padding: 8px;
	font-size: 10px
}

.connectSourceStatus {
	color: #219653;
	font-size: 10px
}

.buySubscriptionTitle {
	color: black;
	font-size: 10px;
	font-weight: 800
}

.dataEndStatus {
	margin-right: 10px;
	font-size: 14px
}

.ended {
	color: red
}

.lastMonth {
	color: #E39800
}

.active {
	color: #219653
}

.cardWrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px
}

.loginPasswordText {
	font-family: Monaco, monospace
}

.iconCopy {
	color: #8090B8
}

.closeModalButton {
	position: absolute;
	right: 16px;
	top: 16px;
	width: 32px !important;
	height: 32px;
	background-color: #F6F8FD;
	padding: 0px
}

.modalTitle {
	margin-bottom: 24px !important
}

.formWrapper {
	position: relative;
	width: 100%
}

.inputWrapper {
	width: 360px;
	color: #4B4F55;
	font-weight: 400px;
	margin-bottom: 0px
}

.buttonsAreaWrapper {
	display: flex;
	height: 56px;
	margin-top: 24px;
	gap: 24px
}

.primaryButton {
	width: 168px;
	border-radius: 4px;
	font-size: 14px !important
}

.discardButton {
	border: 1px solid #CED9EE !important;
	background: transparent !important;
	color: #2E496F !important
}

.confirmButton {
	background: #2E496F
}

.justifyContentCenter {
	justify-content: center;
}

.modalWarningsWrapper {
	display: flex;
	justify-content: flex-end;
	padding-inline: 16px !important;
	height: 56px !important
}

.closeWarningsButton {
	width: 32px !important;
	height: 32px !important;
	background-color: #F6F8FD !important;
	padding: 0
}

.modalWarningsTitleWrapper {
	padding-inline: 48px !important;
	padding-bottom: 32px !important;
	display: flex;
	position: relative
}

.tasksModalGrid {
	display: grid;
	grid-template-columns: 1fr auto;
}

.fontSizeLarge {
	font-size: 30px !important;
}

.flexJustColumn {
	display: flex;
	flex-direction: column
}

.greenColor {
	color: green
}

.blackBoldText {
	color: black;
	font-weight: 800;
}

.modalListDetailsColumnOverflow {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis
}

.drawerWrapper {
	position: relative;
}

.modalListDrawerContentWrapper {
	z-index: 40000;
	background: rgb(22, 119, 255, .2);
	height: 100%;
	width: 100%;
	position: absolute
}

.renewButton {
	background: #2E496F;
	width: 100px
}

.hugeFont {
	font-size: 40px;
}

.greyColor {
	color: #7A7D86
}

.checkListButton {
	width: 200px
}

.addInstanceButton {
	width: 185px;
	background: transparent;
}

.modalListDetailFormWrapper {
	width: 100%;
	flex-wrap: nowrap;
	position: relative
}

.searchIcon {
	color: #C1C2C9 !important
}

.modalComfirmWrapper {
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: column;
	gap: 24px;
	position: relative
}

.modalComfirmButtons {
	flex-grow: 1;
}

.primaryConfirmBtn {
	background: #2E496F;
}

.confirmBtnWrapper {
	display: flex;
	gap: 24px
}

.littleMarginBottom {
	margin-bottom: 10px !important;
}

.middleMarginBottom {
	margin-bottom: 20px !important;
}

.successCard {
	border: 1px solid #91caff;
	background: #e6f4ff
}

.changePasswordOkBtn {
	background: #2E496F;
	height: 48px;
	padding-inline: 28
}


.textOverflow {
	overflow-wrap: break-word;
	word-break: break-word;
	white-space: normal
}

.nameOverflow {
	overflow-wrap: break-word;
	word-break: normal;
	white-space: normal
}
