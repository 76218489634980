.menu {
	width: 200px;
}

.menu ul {
	padding: 0 !important;
	border-radius: 4px !important;
	transform: translateY(8px);
}

.menu li {
	padding: 8px 12px !important;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-radius: 0 !important;
}

.menu svg {
	color: #6679A9 !important;
}

.menu li:last-child {
	border-top: 1px solid #E9EEF7;
}

.menu :global(.ant-dropdown-menu-item-active) {
	background-color: #F6F8FD !important;
}

.menu :global(.ant-dropdown-menu-item-active) svg {
	color: #327FEF !important;
}

.headerWrapper {
	height: 52px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: white;
	padding: 10px 40px;
}

.headerTitle {
	font-weight: bold;
	margin: 0px
}

.headerDivider {
	font-size: 28px
}

.headerBellButton {
	padding-inline: 8px;
}

.headerBellButtonBadge {
	padding: 0px 4px
}

.headerBellButtonIcon {
	color: #6679A9
}

.avatarButton {
	padding: 0px
}

.avatar {
	background-color: black
}

.headerMenuItem {
	display: flex;
	align-items: center;
	gap: 1em;
}