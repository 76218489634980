.menu {
	background-color: transparent;
}

.menu :global(.ant-menu-item) {
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 8px 32px;
	gap: 12px;
	border-radius: 0;
}

.menu :global(.ant-menu-item)>* {
	flex-shrink: 0;
}

.menu :global(.ant-menu-item-selected)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 3px;
	height: 100%;
	background-color: white;
	border-radius: 0 3px 3px 0;
}

.link {
	text-decoration: none;
	color: #989AA5;
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 8px 24px;
	gap: 23px;
	font-weight: 400;

}

.link:hover {
	text-decoration: none;
	color: white;
}

.sidebarMenuWrapper {
	height: 100vh;
	position: sticky;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #000;
	background-image: linear-gradient(0deg, rgba(43, 43, 67, 0.2) 0, #000 650px), url('../../../images/sidebarBackground.png');
	background-position: 0 0, right bottom;
	background-size: 100% 100%, auto 650px;
	background-repeat: repeat, no-repeat;
}

.parusLogo {
	height: 30px;
	width: fit-content;
	margin-top: 20px;
	margin-bottom: 2px;
	transition: margin 250ms;
}

.collapseButton {
	width: 18px;
	height: 32px;
	align-self: end;
	padding: 0px;
	border: none;
	border-radius: 2px 0 0 2px;
	margin-bottom: 16px;
	color: #CED9EE;
}