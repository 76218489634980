.form :global(.ant-form-item-label) {
	padding: 0;
}

.form :global(.ant-form-item-label) label {
	color: #4B4F55;
	height: fit-content !important;
}

.form :global(.ant-form-item-control) input {
	border-radius: 4px !important;
	height: 48px;
	padding: 12px 16px;

}

.form :global(.ant-input-password) input {
	height: auto;
	padding: 0;
}

.form :global(.ant-input-password) {
	border-radius: 4px !important;
	height: 48px;
	padding: 12px 16px;
}

.form :global(.ant-form-item-control-input) {
	min-height: 20px !important;
}

.input :-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.formItem {
	display: flex !important;
	gap: 24px;
	justify-content: space-between !important;
	/* width: '100% !important'  */
}


.setPasswordFormHeaderWrapper {
	display: flex;
	justify-content: flex-end;
	padding-inline: 16px;
	height: 56px;
}

.closeSetPasswordFormIcon {
	width: 12px;
	height: 12px
}

.closeSetPasswordFormButton {
	width: 32px;
	height: 32px;
	background-color: #F6F8FD;
	padding: 0;
}

.setPasswordFormWrapper {
	padding: 80px 64px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.setPasswordFormTitle {
	margin-bottom: 24px !important;
}

.setPasswordFormButtonsWrapper {
	display: flex;
	justify-content: space-between;
	height: 56px;
}

.setPasswordFormDeclineButton {
	width: 150px;
	border-radius: 4px;
	font-size: 14px;
	border: 1px solid #CED9EE;
	background: transparent;
	color: #2E496F
}

.setPasswordFormApproveButton {
	width: 150px;
	border-radius: 4px;
	font-size: 14px;
	background: #2E496F;
}

.setPasswordSuccessModalWrapper {
	padding: 80px 64px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: center;
	align-self: center;
	gap: 24px;
}

.setPasswordSuccessModalTitle {
	margin: 0;
	text-align: center;
	font-size: 28px;
	font-weight: 600;
	line-height: 42px;
	color: #4B4F55;
}

.setPasswordSuccessModalSubTitle {
	margin: 0;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #4B4F55;
}

.setPasswordSuccessModalButton {
	width: 192px;
	border-radius: 4px;
	font-size: 14px;
	background: #2E496F;
}