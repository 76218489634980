.inputUrl :-webkit-autofill,
inputUrlt:-webkit-autofill:hover,
inputUrl:-webkit-autofill:focus,
inputUrl:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.tabs :global(.ant-tabs-nav) {
	margin-bottom: 0;
	color: #8090B8;
}

.tabs :global(.ant-tabs-nav)::before {
	border-bottom: 1px solid #E9EEF7;
}

.tabs :global(.ant-tabs-tab) {
	padding: 12px 24px;
}

.limitsCards {
	display: grid;
	gap: 6px;
	grid-template-columns: repeat(5, 1fr);
}

.limitsCards>div {
	padding: 20px 16px;
	background: #F6F8FD;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.limitsCards  {
	font-size: 16px;
}

.limitsCards>div> :first-child {
	color: #6679A9;
	text-transform: uppercase;
	font-size: 12px;
}

.settingsBtn {
	color: #327FEF;
	background-color: transparent;
	border-color: #327FEF;
	border-radius: 4px;
	font-size: 14px;
	height: 40px;
	width: 148px;
}

.settingsBtn:hover {
	color: #0047C7 !important;
	border-color: #0047C7 !important
}

.sandboxBtn {
	width: 38px;
	height: 38px;
	z-index: 15;
	color: white;
	background-color: #2E496F;
	border: none;
	border-radius: 4px;

}

.sandboxBtn:hover {
	background-color: #233856 !important;
	color: white !important;
}

.sandboxSubmitBtn {
	width: 100%;
	height: 44px;
	z-index: 15;
	color: white;
	background-color: #2E496F;
	border: none;
	border-radius: 4px;

}

.sandboxSubmitBtn:hover {
	background-color: #233856 !important;
	color: white !important;
}


.sandboxDeclineBtn {
	width: 100%;
	height: 44px;
	z-index: 15;
	color: #2E496F;
	background-color: white;
	border: 1px solid #CED9EE;
	border-radius: 4px;

}

.sandboxDeclineBtn:hover {
	background-color: #F5F5F5 !important;
	color: #2E496F !important;
	border: 1px solid #CED9EE !important;
}

.graphsTabs :global(.ant-tabs-nav-wrap) {
	display: flex;
	justify-content: flex-end;
	z-index: 10;
	position: relative;
	bottom: 51px
}

.graphsTabs :global(.ant-tabs-tab) {
	color: #8090B8;
}

.graphsTabs :global(.ant-tabs-tab:hover) {
	color: black;
}

.graphsTabs :global(.ant-tabs-tab-active) {
	-webkit-text-fill-color: black;
	/* color: black; */
	text-shadow: none;
}

.graphsTabs :global(.ant-tabs-ink-bar) {
	background-color: black;
}

.graphsTabs :global(.ant-tabs-nav)::before {
	border-bottom: none;
}

.graphsTabs :global(.ant-tabs-tab-btn:active) {
	color: black !important;
}

.graphsTabs :global(.ant-tabs-tab-btn) {
	color: #8090B8 !important;
}

.table :global(.ant-table-thead) th:first-child {
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	padding: 16px 0 0 24px !important;
	font-size: 12px !important
}

.table :global(.ant-table-thead) th:last-child {
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	padding: 16px 8px 0 26px !important;
	font-size: 12px !important
}

.table :global(.ant-table-thead) th {
	padding: 16px 0 0 26px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px !important;
}

.table :global(.ant-table-cell) {
	vertical-align: top;
	padding: 8px 0 8px 26px !important;
	font-size: 13px;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	cursor: pointer;
}

.table :global(.ant-table-cell):first-child {
	vertical-align: top;
	font-style: normal;
	background-color: #FCFDFF;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	padding: 8px 0 8px 24px !important;
}

.table :global(.ant-table-cell):last-child {
	vertical-align: top;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	padding: 8px 20px 8px 26px !important;
}

.table :global(.ant-table-cell)::before {
	width: 0 !important;
}


.mainTabs :global(.ant-tabs-nav-wrap) {
	z-index: 10;
}

.mainTabs :global(.ant-tabs-tab) {
	color: #8090B8 !important;
	font-size: 16px
}

.mainTabs :global(.ant-tabs-tab:hover) {
	color: black;
}

.mainTabs :global(.ant-tabs-tab-active) {
	-webkit-text-fill-color: black;
	text-shadow: none;
}

.mainTabs :global(.ant-tabs-ink-bar) {
	background-color: black;
}

.mainTabs :global(.ant-tabs-nav)::before {
	border-bottom: none;
}

.mainTabs :global(.ant-tabs-tab) {
	color: black;
	font-weight: 400 !important
}

.mainTabs :global(.ant-tabs-tab-btn:active) {
	color: black !important;
}

.mainTabs :global(.ant-tabs-tab-btn) {
	color: #8090B8 !important;
}

.customProgress :global(.ant-progress-text) {
	color: #7A7D86 !important;
	font-weight: 500;

}

.customProgress :global(.ant-progress-inner) {
	width: 113%;
}

.tableContainer {
	height: 100%;
	background: white;
	border-radius: 8px;
	padding: 0px
}

.stackedBarChartWrapper {
	height: 330px;
	width: 100%
}

.legend {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
	width: 100%;
	height: 8px;
	margin-top: 16px
}

.legendMarker {
	height: 16px;
	width: 16px;
	border-radius: 6px
}

.legendMarker.malwareMarker {
	background-color: #EB5757
}

.legendMarker.graywareMarker {
	background-color: #F7BCBC
}

.legendText {
	font-size: 12px;
}

.detailsReportDownloadIconWrapper {
	height: 100%;
	width: 90%;
	color: #8090B8
}

.detailsDrawerItem {
	border-bottom: 1px solid #E9EEF7;
	padding: 8px 0px 8px 0px
}

.fileInfoDrawerItem {
	padding: 8px 0px 8px 0px
}

.detailsDrawerCloseIconWrapper {
	display: flex;
	justify-content: flex-end;
}

.detailsDrawerCloseIconButton {
	height: 32px;
	width: 32px;
	border: none;
	border-radius: 2px;
	box-shadow: none;
	background: #F6F8FD
}

.detailsDrawerWrapper {
	padding: 0px 30px 0px 30px
}

.detailsDrawerTitle {
	color: #333333;
	font-size: 28px;
	font-weight: 400
}

.detailsDrawerDetailsTitle {
	color: #7A7D86;
	padding-left: 24px;
	font-weight: 400
}

.detailsDrawerDetailsValues {
	display: flex;
	align-items: center;
	color: #4B4F55;
	font-weight: 400
}

.detailsDrawerTableTitle {
	color: #333333;
	margin: 14px 0px 14px 0px
}

.detailsDrawerErrorsWrapper {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 12px
}

.detailsDrawerErrorsText {
	font-weight: 400;
	color: #EB5757
}

.fileCheckerResultErrorWrapper {
	width: 60%
}

.fileCheckerResultErrorText {
	color: #7A7D86 !important;
	font-weight: 400 !important;
	font-size: 13px !important
}

.fileCheckerResultWrapper {
	width: 94%;
	font-weight: 500;
	border-radius: 4px;
	padding: 14px 16px 14px 16px;
	display: flex;
	justify-items: center;
	align-content: space-between
}

.fileCheckerResultContentWrapper {
	display: flex;
	gap: 14px;
	align-items: center;
	width: 100%
}

.fileCheckerResultFileIcon {
	font-size: 36px;
	align-self: center,
}

.fileCheckerResultFileIcon.successUpload {
	color: #219653
}

.fileCheckerResultFileIcon.errorUpload {
	color: #EB5757
}

.fileCheckerResultFileIcon.loading {
	color: #4B4F55
}

.fileCheckerErrorResultTextWrapper {
	display: flex;
	justify-content: space-between;
	width: 100%
}

.fileCheckerResultTextWrapper {
	display: flex;
	flex-direction: column;
	width: 100%
}

.fileCheckerResultTextFilename {
	font-size: 15px;
	overflow-wrap: break-word;
	word-break: break-word;
	flex-wrap: nowrap
}

.fileCheckerResultTextFilename.errorFilename {
	width: 60%
}

.fileCheckerResultFileHashOrSizeWrapper {
	display: flex;
	align-items: center;
	gap: 5px;
}

.fileCheckerResultFileHashOrSizeWrapper.errorHash {
	justify-self: flex-end;
}

.fileCheckerResultFileHashOrSizeText {
	color: #7A7D86;
	font-weight: 500;
	font-size: 12px
}

.copyIcon {
	color: #8090B8;
	width: 16px;
	height: 16px
}

.fileCheckerResultErrorIconAndText {
	color: #EB5757;
	font-size: 12px
}


.fileCheckerResultSuccessAlertWrapper {
	display: flex;
	width: 30%;
	flex-direction: column;
	align-items: flex-end;
	gap: 5px
}

.fileCheckerResultSuccessAlert {
	display: flex;
	gap: 5px;
	align-items: center;
}

.fileCheckerResultSuccessAlertIcon {
	font-size: 10px;
	color: #219653
}

.fileCheckerResultSuccessAlertText {
	text-align: start;
	font-size: 12px;
	color: #219653
}

.fileCheckerResultFileUploadedAgoText {
	color: #7A7D86;
	font-weight: 500;
	font-size: 12px
}

.fileCheckerResultFileUploadedAgoText.dash {
	padding: 0px 4px 0px 4px
}

.noticeWrapper {
	width: 60%
}

.noticeText {
	color: #7A7D86;
	font-weight: 400;
	font-size: 13px
}

.noticeLink {
	color: #327FEF;
	cursor: pointer
}

.tableFontWeight {
	font-weight: 400;
}

.collapseIcon {
	font-size: 14px;
	padding-top: 8px;
	z-index: 15
}

.sandboxCollapse {
	background-color: white;
	padding: 16px 24px 16px 24px !important;
	align-content: center;
	border-radius: 8px;
	font-size: 18px;
	font-weight: 600;
	min-height: 60px;
}

.sandboxCollapseTitle {
	z-index: 11;
	position: absolute;
}

.hashInput {
	width: 99%;
	height: 44px;
	border-radius: 4px;
	padding-right: 2px;
}

.sendHashButtonIcon {
	font-size: 14px;
}

.fileInput {
	display: none;
}

.fileChekerErrorsWrapper {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 12px
}

.fileChekerErrorsText {
	font-weight: 400;
	color: #EB5757
}

.metricsAreaGreyText {
	color: #7A7D86
}

.metricsAreaListItem {
	display: flex;
	justify-content: space-between
}

.metricsAreaCardBadge {
	display: inline;
	border-radius: 2px;
	padding: 0px;
	padding-left: 0px;
	text-transform: uppercase
}

.metricsAreaCardBadge.benign {
	background-color: #E0F6EA;
	color: #219653 !important
}

.metricsAreaCardBadge.grayware {
	background-color: #FFF9EC;
	color: #E39800 !important
}

.metricsAreaCardBadge.malware {
	background-color: #FFECEC;
	color: #EB5757 !important
}

.metricsAreaWrapper {
	position: relative
}

.sandboxAreaStatisticsTabWrapper {
	display: flex;
	flex-direction: column;
	gap: 24px
}

.sandboxAreaWrapper {
	width: 100%
}

.sandboxAreaTitle {
	margin: 0px !important;
}

.sandboxSettingsButtonWrapper {
	display: flex;
	justify-content: flex-end
}

.sandboxDocumentationDownloadFileIcon {
	color: #8090B8;
}

.sandboxDocumentationWrapper {
	width: 100%;
	position: relative
}

.sandboxDocumentationTable {
	overflow-x: auto
}

.sandboxListDateTimeWrapper {
	display: flex;
	flex-direction: column;
}

.sandboxListTime {
	color: #7A7D86
}


.sandboxListStatusBadge :global(.ant-badge-count) {
	display: flex !important;
	align-items: center !important;
	height: 26px;
	border-radius: 2px !important
}

.sandboxListSha {
	display: flex;
	align-items: center;
}

.sandboxListFileName {
	display: flex;
	gap: 5px;
	width: 90%;
	overflow-wrap: break-word;
	word-break: break-word;
	flex-wrap: nowrap
}

.sandboxListOpenDetailsIcon {
	font-size: 17px;
	color: #8090B8;
	cursor: pointer;
	padding-top: 3px
}

.sandboxListAreaWrapper {
	width: 100%;
	position: relative;
	overflow: auto;
}

.sandboxListTotalListItemsCountWrapper {
	display: flex;
	justify-content: flex-start
}

.sandboxListTotalListItemsCountText {
	font-size: 13px;
	line-height: 0px;
}

.sandboxListTotalListItemsCountText.title {
	color: #8090B8;
	margin-right: 8px;
}

.statisticFiltersRadioGroup {
	padding: 0px 10px;
	font-size: 20px;
	display: flex !important;
	flex-direction: column !important;
	gap: 10px
}

.statisticFiltersRadioGroupText {
	font-weight: 400 !important;
}

.statisticFiltersRadioGroupDivider {
	margin: 8px 0px;
}

.statisticsFiltersWrapper {
	display: flex;
	justify-content: space-between;
}

.statisticsFiltersSelector {
	width: 100%;
	text-align: left;
	height: 44px
}

.statisticsFiltersButtons {
	border-radius: 4px;
	height: 100%;
	width: 48%
}

.sandboxTableFiltersAreaDatePicker {
	width: 200px
}

.sandboxTableFiltersAreaDatePickerListItem {
	cursor: pointer;
	border: none !important
}

.frequencyFilterButton {
	background: transparent;
	border: none
}

.sandboxTableFiltersFirstRow {
	margin-bottom: 16px;
}

.searchIcon {
	color: #C1C2C9
}

.closeSearchIcon {
	color: red;
	cursor: pointer;
}

.searchInput {
	width: 100%;
	height: 44px;
	border-radius: 4px
}

.chooseIntervalWrapper {
	width: 100%
}

.chooseWrapperButton {
	display: flex;
	align-items: center;
	width: 100%;
	text-align: center;
	height: 44px
}

.calendarIcon {
	padding: 5px 0;
	color: #6679A9
}

.showDataFilter {
	font-size: 14px;
	padding: 0px 5px;
	margin-top: 2px
}

.filtersButton {
	border-radius: 4px;
	height: 100%;
	width: 100%
}

.filtersButton.renew {
	color: white !important;
	background: #2E496F !important
}

.filtersButton.renew :hover {
	color: white !important;
	background: #2E496F !important
}

.sandboxFiltersAreaSecondRow {
	height: 44px
}

.sandboxFiltersStatusSelector {
	width: 100%;
	height: 100%;
	margin-right: 12px
}

.sandboxFiltersVerdictSelector {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	margin-right: 12px
}

.utilsDownloadFileIcon {
	height: 100%;
	width: 90%;
	color: #8090B8
}

.utilsDateTimeWrapper {
	display: flex;
	gap: 5px
}

.utilsDateTime {
	font-size: 12px;
}

.utilsDateTime.date {
	color: #4B4F55 !important
}

.utilsDateTime.time {
	color: #7A7D86 !important
}

.utilsBadge :global(.ant-badge-count) {
	border-radius: 2px;
	font-size: 12px;
}

.utilsBadge.malwareOrHighRiskOrCriticalRiskOrDefault :global(.ant-badge-count) {
	color: #EB5757
}

.utilsBadge.clearOrBenign :global(.ant-badge-count) {
	color: #219653
}

.utilsBadge.graywareOrMediumRisk :global(.ant-badge-count) {
	color: #E39800
}

.utilsBadge.notApplicable :global(.ant-badge-count) {
	color: #7a2300
}

.utilsBadge.adwareAndOther :global(.ant-badge-count) {
	color: #cc7810
}

.utilsBadge.notCategorizedOrUnknown :global(.ant-badge-count) {
	color: grey
}

.utilsBadge.notAVirusOrUnsupportedOrLowRisk :global(.ant-badge-count) {
	color: #cc7810
}

.utilsBadge.error :global(.ant-badge-count) {
	color: #12020c
}

.utilsBadge.comingSoon :global(.ant-badge-count) {
	border-color: darkgray;
	color: darkgray
}

.darkGreyColor {
	color: #4B4F55 !important
}

.lightGreyColor {
	color: #7A7D86 !important
}

.utilsIpAndUrlWrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem
}

.utilsShaWrapper {
	display: flex;
	align-items: center;
}

.utilsShaAndFilenameText {
	height: 100%;
	width: 90%;
	overflow-wrap: break-word;
	word-break: break-word;
	flex-wrap: nowrap;
	color: #4B4F55
}

.utilsVerdictWrapper {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.utilsLink {
	text-decoration: none;
}

.utilsFileSampleDownloadIcon {
	height: 100%;
	width: 90%;
	color: #EB5757
}

.redColor {
	color: #EB5757 !important
}

.utilsIpAndUrl {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	overflow-wrap: break-word;
	word-break: break-word;
	flex-wrap: nowrap
}

.utilsUrlBadgesArea{
	display: flex;
	gap: 5px
}
.tableLoader {
	position: fixed !important;
	top: 50% !important
  }
  .iconDown {
	font-size: 12px !important;

	margin-left: 5px;
  }
  
